@use "@angular/material" as mat;

@use "./global-styles/global-vars";
@use "./global-styles/fonts";
@use "./global-styles/util";
@use "./global-styles/bootstrap-grid";
@use "./global-styles/components/och-checkbox";
@use "./global-styles/components/och-radio";
@use "./global-styles/components/och-input-field";
@use "./global-styles/admin-common";
@use "./global-styles/edit-course-common";
@use "./global-styles/button-common";
@use "./global-styles/custom-domain-common";
@use "./global-styles/activate-plan-common";
@use "./global-styles/new-components";
@use "./global-styles/user-avatar";
@use "./global-styles/community-common";
@use "./global-styles/page-builder-common";
@use "./global-styles/analytics-common";
@use "./global-styles/general";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Inter, "Work Sans", sans-serif;
}

body.modal-open {
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

*,
::after,
::before {
  box-sizing: border-box;
}

$custom-typography: mat.m2-define-typography-config($font-family: '"Inter", "Work Sans", sans-serif'
  );

@include mat.elevation-classes();
@include mat.app-background();

$dark-primary-text: rgba(black, 0.87);

$light-primary-text: white;

$mat-custom-grey: (
  50: #fafafa,
  100: #f5f5f5,
  200: #eeeeee,
  300: #e0e0e0,
  400: #bdbdbd,
  500: #9e9e9e,
  600: #757575,
  700: #616161,
  800: #424242,
  900: #212121,
  A100: #ffffff,
  A200: #eeeeee,
  A400: #bdbdbd,
  A700: #616161,
  contrast: (50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white)
);

$mat-custom-accent: (
  50: #fce4ec,
  100: #f8bbd0,
  200: #f48fb1,
  300: #f06292,
  400: #ec407a,
  500: #e91e63,
  600: #d81b60,
  700: #c2185b,
  800: #ad1457,
  900: #880e4f,
  A100: #ff80ab,
  A200: #eb3b3b,
  A400: #f50057,
  A700: #c51162,
  contrast: (50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white)
);

$primary: mat.m2-define-palette($mat-custom-grey, 500);

$accent: mat.m2-define-palette($mat-custom-accent, A200, A100, A400);

$theme: mat.m2-define-light-theme((color: (primary: $primary,
        accent: $accent ),
      typography: $custom-typography,
      density: 0));

@include mat.all-component-themes($theme);

.mat-elevation-z1 {
  box-shadow:
    0 2px 1px -1px #0003,
    0 1px 1px #00000024,
    0 1px 3px #0000001f;
}

.mat-elevation-z2 {
  box-shadow:
    0 3px 1px -2px #0003,
    0 2px 2px #00000024,
    0 1px 5px #0000001f;
}

.mat-elevation-z3 {
  box-shadow:
    0 3px 3px -2px #0003,
    0 3px 4px #00000024,
    0 1px 8px #0000001f;
}

.mat-elevation-z4 {
  box-shadow:
    0 2px 4px -1px #0003,
    0 4px 5px #00000024,
    0 1px 10px #0000001f;
}

.mat-elevation-z5 {
  box-shadow:
    0 3px 5px -1px #0003,
    0 5px 8px #00000024,
    0 1px 14px #0000001f;
}

.mat-elevation-z6 {
  box-shadow:
    0 3px 5px -1px #0003,
    0 6px 10px #00000024,
    0 1px 18px #0000001f;
}

.mat-elevation-z7 {
  box-shadow:
    0 4px 5px -2px #0003,
    0 7px 10px 1px #00000024,
    0 2px 16px 1px #0000001f !important;
}

.mat-elevation-z8 {
  box-shadow:
    0 5px 5px -3px #0003,
    0 8px 10px 1px #00000024,
    0 3px 14px 2px #0000001f;
}

.mat-elevation-z9 {
  box-shadow:
    0 5px 6px -3px #0003,
    0 9px 12px 1px #00000024,
    0 3px 16px 2px #0000001f;
}

.mat-elevation-z10 {
  box-shadow:
    0 6px 6px -3px #0003,
    0 10px 14px 1px #00000024,
    0 4px 18px 3px #0000001f !important;
}


.email-template-card.main-cls-css {
  padding-right: 39px;
  padding-bottom: 52px;
}

.email-template-card.main-cls-css .card-margin {
  margin: 0;
  padding: 0 0 0 6.7rem !important;
}

.color-sample {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}



.table-header-top .filter span {
  color: #667182;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}



.table-header-top .filter span .filter-select mat-label {
  color: #7E8DA0;
  font-family: Inter;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.table-responsive.email-tables td {
  font-size: 13px !important;
}

.admin-screen-container .card-body .card-margin .theme-pickers .color-platte {
  width: 60%;
}

.admin-screen-container .card-body .card-margin .select-label {
  color: #11294F;
  font-family: Inter;
  font-size: 13px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.admin-screen-container .card-body .card-margin .mat-mdc-text-field-wrapper {
  background: #fff;
  border-radius: 6px;
  // border: 1px solid #D7E2EA;
  background: #FFF;
  box-shadow: 0px 1px 3px 0px rgba(126, 141, 160, 0.16);
}

.admin-screen-container .card-body .card-margin p.color-platte-label {
  color: #667182;
  font-family: Inter;
  font-size: 13px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.admin-screen-container .card-body .card-margin color-field input.form-control {
  padding: 0 !important;
  background: transparent !important;
  border: unset !important;
  color: #A1B1C6;
  font-family: Inter;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


.admin-screen-container .card-body .card-margin color-field label {
  color: #6E7D92 !important;
  font-size: 11px !important;
}

.admin-screen-container .card-body .card-margin .logo-upload {
  background: transparent;
  border-bottom: 1px solid #ECECEC;
  border-radius: 0;
  padding-bottom: 26px;
}

.admin-screen-container .card-body .card-margin .logo-upload:last-child {
  border: none;
}

.admin-screen-container .card-body .card-margin .logo-upload .radio-input input {
  accent-color: #2D405D;
}

.admin-screen-container .card-body .card-margin .social-media-action-item .radio-input input {
  accent-color: #2D405D;
}

.admin-screen-container .card-body .card-margin.ng-untouched.ng-pristine .logo-upload.header-img {
  border-bottom: 0;
  padding: 0 0 10px 5px;
}

.ng-untouched.ng-pristine.ng-valid .form-group label.main-label {
  color: #2D405D;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: 10px !important;
}

.ng-untouched.ng-pristine.ng-valid .form-group input.form-control {
  border-radius: 6px;
  border: 1px solid #D7E2EA;
  background: #FFF;
}

button.btn-light span {
  color: #11294F;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.theme .btns-email-branding .btn-primary {
  background: #11294F;
}

.admin-screen-container .card-body .card-margin .logo-upload .title {
  padding-right: 20px;
}

form.ng-untouched.ng-pristine.ng-valid .btns-email-branding {
  flex-wrap: wrap;
}

form.ng-untouched.ng-pristine.ng-valid .btns-email-branding button.btn-admin-primary.btn-admin-icon svg {
  stroke: #fff !important;
  --stroke-color: #ffffff;
}

form.ng-untouched.ng-pristine.ng-valid .btns-email-branding button.btn-admin-primary.btn-admin-icon {
  border-radius: 6px;
  border: 0.5px solid #DDE3EA;
  background: #2973E0;
  color: #ffff;
}

.email-addresss {
  display: flex;
  justify-content: space-between;
}

.email-addresss h3 {
  color: #2D405D;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  padding: 0;
}

.email-addresss {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 39px 0px 39px 6px;
  //border-top: 1px solid #ECECEC;
}

.email-addresss h3 {
  color: #2D405D;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  padding: 0;
}

.email-addresss .right-bar {
  width: 70%;
}

.email-addresss .right-bar form {
  display: flex;
  justify-content: end;
  gap: 7px;
}

.email-addresss .right-bar form button {
  border-radius: 10px !important;
  background: #0578FF !important;
  color: #FFF;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: auto;
  height: auto;
  padding: 10px 25px;
}

.email-addresss .right-bar form input {
  border-radius: 10px;
  border: 1px solid #ECF0F6;
  background: #FFF;
  width: 100%;
  max-width: 273px;
  padding: 14px;
}

.email-addresss .right-bar form input::placeholder {
  color: #7E8DA0;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.email-addresss .left-bar {
  width: 30%;
}

.table-header-top.table-header-top-ba .search-box-input input {
  padding: 14px 10px 14px 30px !important;
  color: #7E8DA0;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.table-header-top.table-header-top-ba .search-icon {
  top: 13px !important;
}

.table-container[_ngcontent-ng-c3871573357] .table-responsive[_ngcontent-ng-c3871573357] table[_ngcontent-ng-c3871573357] td[_ngcontent-ng-c3871573357] {
  color: #2D405D;
}

.table-container[_ngcontent-ng-c3871573357] .table-responsive[_ngcontent-ng-c3871573357] table[_ngcontent-ng-c3871573357] tbody[_ngcontent-ng-c3871573357] tr[_ngcontent-ng-c3871573357]:last-child {
  border-bottom: 1px solid rgba(223, 228, 238, 0.5) !important;
}

ul.side-menu-list ul.sub-menu-list.show li.sub-menu-link.active {
  background: transparent !important;
  font-weight: 600 !important;
  color: #2D405D !important;
}

ul.sub-menu-list.show li {
  color: #667182 !important;
}

li.side-menu-link.show .main-link {
  border-radius: 10px;
  background: #EFF6FF;
}

ul.side-menu-list li.side-menu-link span.menu-title {
  font-weight: 400 !important;
  font-size: 13px !important;
}

li.side-menu-link.show .main-link svg {
  --stroke-color: #2973E0 !important;
}

li.side-menu-link.show .main-link span.menu-title {
  color: #2973E0;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 42px;
}

.mobile-back {
  display: none;
}

.email-template-card-header p {
  text-align: left;
}

.admin-screen-container .card-body .card-margin .mat-mdc-text-field-wrapper span {
  color: #4B5D7A !important;
}


.tab-content.welcome-main .edit-course-card-body .btn-actions button {
  border-radius: 6px;

  // background: #0F274A;

}

.course-header .mat-mdc-tab-header-pagination-chevron {
  border-color: #2973E0;
}

.course-header .course-status .course-extra-container {
  flex-wrap: wrap !important;
}

.integrations-main .card-body .form-group input::placeholder {
  color: #667182;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.integrations-main .card-body button {
  border-radius: 10px;
  border: 1px solid #D7E2EA;
  background: #FFF;
  color: #6F7F95;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.integrations-main .card-body button svg {
  --stroke-color: #6F7F95;
}

.integrations-main .card-body button:hover {
  background: #2973E0;
}

.integrations-main .card-body button:hover svg {
  --stroke-color: #ffffff;
}

.integrations-main .card-body button.btn-edit-email {
  border: 1px solid #2973E0;
  color: #2973E0;
}

.integrations-main .card-body button.btn-edit-email:hover {
  color: #ffffff;
}

.integrations-main .card-body button.btn-edit-email svg {
  --stroke-color: #2973E0;
}

.integrations-main .card-body button.btn-edit-email:hover svg {
  --stroke-color: #ffffff;
}

.admin-screen-container.integrations-main .form-group .form-control {
  border-radius: 10px;
  border: 1px solid #A1B1C6;
  background: #F7FAFC;
}

.select-course compose-email .card-body .form-group span.sub-label {
  font-size: 11px;
}

.select-course compose-email .card-body .form-group .form-widget input {
  border-radius: 10px;
  background: #F7F9FC !important;
  border: none !important;
}

.select-course .selection-clear-btn button.btn-link.btn-clear-all span {
  color: #A1B1C6 !important;
  font-weight: 400;
}

.select-course .recipients .form-field input::placeholder {
  color: #98A2B3 !important;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


.select-course input {
  background: #f7f9fc !important;
}

.select-course input::placeholder {
  color: #98A2B3 !important;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.select-course .ql-editor.ql-blank::before {
  font-style: normal;
  color: #98A2B3;
  font-family: Inter;
  font-size: 13px;
}

.side-menu-container ul.side-menu-list .main-link.active span.menu-title {
  font-weight: 600 !important;
}

.select-course.filter-select mat-form-field {
  border-radius: 10px;
  border: 1px solid #ECF0F6 !important;
  background: #FFF !important;
  min-width: 200px;
  width: 100% !important;
}

.select-course.filter-select mat-form-field mat-label {
  color: #7E8DA0 !important;
  font-family: Inter;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.main-cls-css .table-header .filter .filter-select .arrow-icon {
  top: 50% !important;
  transform: translateY(-82%);
}
.edit-template-texteditor span.ql-header.ql-picker {
  width: auto;
}


.letter-content.custom-letter-content h1 {
  color: inherit;
}

.letter-content.custom-letter-content h2 {
  color: inherit;
}

.letter-content.custom-letter-content h3 {
  color: inherit;
}

.letter-content.custom-letter-content h4 {
  color: inherit;
}

.letter-content.custom-letter-content h5 {
  color: inherit;
}

.letter-content.custom-letter-content h6 {
  color: inherit;
}

.letter-content.custom-letter-content p {
  color: inherit;
}
.letter-content.custom-letter-content div {
  white-space: pre-wrap;
}

// responsive

@media screen and (max-width:1399px) {
  .email-template-card.main-cls-css .card-margin {
    padding: 0 0 0 0rem !important;
  }

  .admin-screen-container .card-body .card-margin .theme-pickers .color-platte {
    width: auto;
  }
}







@media screen and (max-width:1245px) {
  .main-cls-css .table-header .search-input-wrapper {
    width: 100%;
  }

  .main-cls-css .table-header .search-input-wrapper .search-box-input {
    width: 100%;
  }

  .main-cls-css .table-header .search-input-wrapper .search-box-input input {
    width: 100%;
  }

  .main-cls-css .email-template-card[_ngcontent-ng-c2444999301] .card-margin[_ngcontent-ng-c2444999301] {
    padding: 0 0 0 6rem;
  }

  .admin-screen-container .card-body .card-margin {
    padding: 0;
  }
    .card-body.email-template-card.main-cls-css .table-header-top.table-header-top-ba .filter {
      width: 100%;
      justify-content: flex-start !important;
      gap: 10px !important;
    }

    .select-course.filter-select {
      width: 100%;
    }
        .select-course.filter-select mat-form-field {
          width: 100% !important;
        }
}


@media screen and (max-width:990px) {
  .main-cls-css .email-template-card[_ngcontent-ng-c2444999301] .card-margin[_ngcontent-ng-c2444999301] {
    padding: 0 0 0 6rem;
  }
}

@media screen and (max-width:991px) {
  .edit-email-template-wrapper .edit-email-actions {
    position: static !important;
  }

  .edit-email-template-wrapper.email-temp-css {
    flex-direction: column-reverse;
    height: auto !important;
  }

  .edit-email-template-wrapper.email-temp-css .edit-email-actions {
    max-width: 42rem !important;
    margin-left: auto;
    margin-right: auto;
  }

  .edit-email-template-wrapper.email-temp-css .email-template {
    width: 97% !important;
    margin-top: 0 !important;
  }

  .edit-email-template-wrapper.email-temp-css .email-template .author-info p {
    word-break: break-all;
  }

  .layout-wrapper.midel-main .row.justify-content-center.midel-bar {
    padding-left: 0px;
  }

  .admin-screen-container .card-body .card-margin .theme-pickers .color-platte {
    width: 100%;
  }
}


@media screen and (max-width:767px) {
  .main-cls-css .table-container[_ngcontent-ng-c2444999301] .email-name[_ngcontent-ng-c2444999301] {
    min-width: 100%;
  }

  .main-cls-css .table-header .filter {
    flex-direction: column;
    width: 100%;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }

  .main-cls-css .table-header .filter .filter-select {
    width: 100%;
  }

  .main-cls-css .table-header .filter .filter-select mat-form-field {
    width: 100%;
  }

  .admin-layout .container {
    max-width: 100%;
  }

  .email-template-card-header {
    flex-direction: column;
    gap: 12px;
  }

  .email-template-card.main-cls-css .card-margin {
    margin-top: 22px;
  }

  .email-template-card.main-cls-css {
    padding-right: 1rem;
  }

  .table-header {
    padding: 1.2rem 12px !important;
  }

  .edit-email-template-wrapper.email-temp-css .edit-email-actions {
    max-width: 100% !important;
    margin-left: auto;
    margin-right: auto;
    box-shadow: none !important;
    padding: 0 !important;
  }

  .edit-email-template-wrapper.email-temp-css .email-template {
    width: calc(100% - 20px) !important;
    margin-top: 0 !important;
    max-width: 100% !important;
  }

  .midel-main .social-media-action-item {
    flex-direction: column;
    align-items: self-start !important;
    gap: 18px !important;
  }

  .midel-main .item-alignment {
    flex-direction: column;
    align-items: self-start !important;
    gap: 23px !important;
  }

  .midel-main .social-media-actions.custom-color-actions .social-media-action-item .socail-media-list {
    margin-left: 33px;
  }

  .midel-main .social-media-actions.custom-color-actions .color-fileds {
    padding-left: 33px;
  }

  .midel-main .social-media-actions .socail-media-list {
    margin-left: 33px;
  }

  form.ng-untouched.ng-pristine.ng-valid .btns-email-branding {
    flex-direction: column;
    margin-top: 30px;
    position: relative;
  }

  form.ng-untouched.ng-pristine.ng-valid .btns-email-branding button.btn-admin-primary.btn-admin-icon {
    margin-bottom: 20px;
  }

  form.ng-untouched.ng-pristine.ng-valid .btns-email-branding:before {
    content: "";
    position: absolute;
    top: 61px;
    left: 0;
    background: red;
    width: 100%;
    height: 1px;
    background: #ECECEC;
  }

  form.ng-untouched.ng-pristine.ng-valid .btns-email-branding .items-right {
    flex-wrap: wrap;
    justify-content: center;
  }

  .mobile-back {
    display: block;
  }

  .email-addresss {
    border-top: none;
    flex-direction: column;
    align-items: self-start;
    padding: 0px 12px 24px;
    gap: 12px;
  }

 .email-addresss .right-bar form {
   justify-content: start;
   width: 100%;
 }
 .email-addresss .right-bar form input {
   max-width: 100%;
 }

  .email-addresss .right-bar {
    width: 100%;
  }

  .email-addresss .left-bar {
    width: 100%;
  }

  .admin-screen-container .card-body .card-margin .theme-sample span {
    text-align: center;
    display: block;
  }

  .integrations-main .card-body h3.card-sub-title {
    text-align: center;
  }

  .integrations-main .card-body {
    padding: 39px 22px 24px;
  }

  .integrations-main .card-body ul {
    padding-left: 17px;
  }

  .admin-screen-container.integrations-main .btn-admin-actions button {
    margin: initial;
  }

  .admin-screen-container.integrations-main .btn-admin-actions {
    justify-content: left !important;
    align-items: self-start;
  }

  .select-course .admin-screen-container {
    margin-top: 0;
  }

  .select-course span.main-label {
    padding-left: 4px;
  }

  .select-course compose-email .card-body .form-send-form .main-label {
    padding-left: 0;
  }

  // .select-course mat-form-field {
  //   margin-top: 10px;
  // }
    .main-cls-css .table-header .filter .filter-select .arrow-icon {
      top: 50% !important;
      transform: translateY(-50%);
    }
}



@media screen and (max-width:575px) {
  .main-cls-css .email-template-card-header .card-icon {
    width: 100px !important;
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

.generate-ai-text-dialog .modal-overlay .modal-container{
  max-width: 600px !important;
}

.suggest-price-modal .modal-overlay .modal-container{
  max-width: 500px !important;
  padding: 20px 25px;
}